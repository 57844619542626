<template>
  <div class="container">
    <pre style="display: none">{{ pagination | pretty_json }}}</pre>
    <h1>Lista de Unidades</h1>
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div class="pa-2 flex-grow-1"></div>
          <div class="pa-2">
            <router-link
              v-if="$can('unity:create')"
              :to="{
                name: 'units_create',
              }"
              class="mb-2 ml-4 btn btn-outline-primary link-edit"
            >
              Novo
              <b-icon
                icon="plus-circle"
                aria-hidden="true"
                class="mx-auto my-auto pl-1"
              ></b-icon
            ></router-link>
          </div>
        </div>

        <b-table striped hover :items="unities" :fields="fields">
          <template #cell(updated_at)="row">
            {{ row.item.updated_at | format_date }}
          </template>
          <template #cell(actions)="row">
            <Actions
              :item="row.item"
              :view_show="false"
              :view_edit="$can('unity:edit')"
              :view_delete="$can('unity:delete')"
              edit_to="units_edit"
              @delete="delete_unity"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="overflow-auto mt-4">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
      ></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import Actions from "@/components/Table/Actions.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Actions },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    ...mapState("unities", ["unities", "pagination"]),
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "name", label: "Nome" },
        { key: "initials", label: "Sigla" },
        { key: "actions", label: "#", class: "col-1" },
      ];
    },
  },
  methods: {
    ...mapActions("unities", ["ActionGetAll", "ActionDelete", "ActionNew"]),
    async delete_unity(unity) {
      try {
        await this.ActionDelete(unity.id);
        this.$notifications.addNotification({
          message: "Registro deletado com sucesso",
          type: "success",
        });
        await this.ActionGetAll({});
      } catch (error) {
        console.log("error", error.data);
        this.$notifications.addNotification({
          message: "Erro ao excluir registro.<br/>" + error.data.message,
          type: "danger",
        });
      }
    },

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
  },
  created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }
    this.ActionGetAll({ page: this.page });
  },
};
</script>

<style></style>
